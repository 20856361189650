<template>
  <div @change="flagDirty">
    <v-toolbar flat class="hqsOrange--text text mt-n5">
      <v-toolbar-title>{{ title }} Lattice Configuration</v-toolbar-title>
      <v-spacer />
      <LoadDropdown v-bind:isDirty="isDirty" />
    </v-toolbar>
    <v-form ref="form" v-model="valid">
      <!-- label input -->
      <v-row class="px-6">
        <v-col cols="5">
          <v-sheet class="text mt-3">Label</v-sheet>
        </v-col>
        <v-col cols="7">
          <v-text-field
            data-cy="label"
            dense
            label
            v-model="label"
            :rules="rule_required"
            @change="flagDirty"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- description input -->
      <v-row class="px-6 mt-n3 mb-3">
        <v-col cols="5">
          <v-sheet class="text mt-3">Description</v-sheet>
        </v-col>
        <v-col cols="7">
          <v-text-field
            data-cy="description"
            dense
            label
            v-model="description"
            :rules="rule_required"
            @change="flagDirty"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- spin representation input (only for spins) -->
      <v-row class="px-6 mt-n5 mb-3" v-if="latticeType.includes('spins')">
        <v-col cols="5">
          <v-sheet class="text">
            Spin Representation
            <InfoDialog info="spinRep"></InfoDialog>
          </v-sheet>
        </v-col>
        <v-col cols="7">
          <v-text-field
            data-cy="spin_representation"
            dense
            type="number"
            label
            v-model.number="spin_representation"
            :rules="rule_intAboveZero"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- site type input -->
      <v-row class="px-6">
        <v-col cols="5">
          <v-sheet class="text"> Site Type </v-sheet>
        </v-col>
        <v-col cols="7">
          <v-select
            data-cy="lattice_type"
            @change="showConversionAlert"
            dense
            v-model="latticeType"
            label=""
            :items="latticeTypes"
            items.color="darkGrey--text"
            :rules="rules_settings"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-expansion-panels multiple class="panels">
        <!-- sites expansion panel -->
        <v-expansion-panel data-cy="sites_panel">
          <v-expansion-panel-header class="text header"
            >Sites</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <SitesTable />
            <SitesTablesSpinfulFermions
              v-if="latticeType.includes('spinful')"
              class="mt-3 mx-n5"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- lattice vectors expansion panel -->
        <v-expansion-panel>
          <v-expansion-panel-header
            data-cy="lattice_vectors_panel"
            class="header text"
            >Lattice Vectors</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="4">
                <v-sheet class="text">
                  Lattice-Vector a
                  <InfoDialog info="vectors"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="lattice_vector_ax"
                  dense
                  type="number"
                  label="x"
                  v-model.number="latticeVectorA[0]"
                  :rules="rules_settings"
                  @input="updateGraphWithDebounce"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="lattice_vector_ay"
                  dense
                  type="number"
                  label="y"
                  v-model.number="latticeVectorA[1]"
                  :rules="rules_settings"
                  @input="updateGraphWithDebounce"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-sheet class="text">
                  Lattice-Vector b
                  <InfoDialog info="vectors" />
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="lattice_vector_bx"
                  dense
                  type="number"
                  label="x"
                  v-model.number="latticeVectorB[0]"
                  @input="updateGraphWithDebounce"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="lattice_vector_by"
                  dense
                  type="number"
                  label="y"
                  v-model.number="latticeVectorB[1]"
                  @input="updateGraphWithDebounce"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- bonds expansion panel -->
        <v-expansion-panel data-cy="bonds_panel">
          <v-expansion-panel-header class="header text"
            >Bonds</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <BondsTable />
            <BondsTablesSpinfulFermions
              v-if="!latticeType.includes('spinless')"
              class="mt-3 mx-n5"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- system expansion panel -->
        <v-expansion-panel data-cy="system_panel">
          <v-expansion-panel-header class="header text"
            >System Settings</v-expansion-panel-header
          >
          <v-expansion-panel-content eager>
            <v-row>
              <v-col cols="4">
                <v-sheet class="text">
                  Cluster Size
                  <InfoDialog info="systemSize"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="cluster_size_x"
                  dense
                  type="number"
                  label="x"
                  v-model.number="clusterSize[0]"
                  :rules="rule_intAboveZero"
                  @input="updateGraph"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="cluster_size_y"
                  dense
                  type="number"
                  label="y"
                  v-model.number="clusterSize[1]"
                  :rules="rule_intAboveZero"
                  @input="updateGraph"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-sheet class="text">
                  Boundary Conditions
                  <InfoDialog info="boundaryConditions"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="8">
                <v-select
                  data-cy="boundary_a"
                  dense
                  v-model="boundaryConditions[0]"
                  label="Boundary Conditions Vector a"
                  :items="boundaryCond"
                  items.color="darkGrey--text"
                  :rules="rule_required"
                  @input="updateGraph"
                  @change="flagDirty"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4"> </v-col>
              <v-col cols="8">
                <v-select
                  data-cy="boundary_b"
                  dense
                  v-model="boundaryConditions[1]"
                  label="Boundary Conditions Vector b"
                  :items="boundaryCond"
                  items.color="darkGrey--text"
                  :rules="rule_required"
                  @input="updateGraph"
                  @change="flagDirty"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="
                latticeType.includes('spinful') || latticeType.includes('spins')
              "
            >
              <v-col cols="2">
                <v-sheet class="text">
                  Sz
                  <InfoDialog info="Sz"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="4" class="ml-n12 mr-12">
                <v-text-field
                  data-cy="Sz"
                  dense
                  type="number"
                  label="Sz"
                  v-model.number="Sz"
                  :rules="rules_integers"
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-sheet class="text">
                  mod Sz
                  <InfoDialog info="modSz"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="mod_Sz"
                  dense
                  type="number"
                  label="mod Sz"
                  v-model.number="mod_Sz"
                  :rules="rule_mod_sz"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="latticeType.includes('fermions')">
              <v-col cols="2">
                <v-sheet class="text">
                  N
                  <InfoDialog info="N"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="4" class="ml-n12 mr-12">
                <v-text-field
                  data-cy="N"
                  dense
                  type="number"
                  label="N"
                  v-model.number="N"
                  :rules="rules_integers"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-sheet class="text">
                  mod N
                  <InfoDialog info="modN"></InfoDialog>
                </v-sheet>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  data-cy="mod_N"
                  dense
                  type="number"
                  label="mod N"
                  v-model.number="mod_N"
                  :rules="rule_mod_n"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- noise expansion panel -->
        <v-expansion-panel data-cy="noise_panel">
          <v-expansion-panel-header class="header text"
            >Noise</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-alert
              outlined
              type="warning"
              text
              prominent
              border="left"
              icon="mdi-rocket-launch-outline"
            >
              This space is meant for future configurations of Noise for your
              lattice.
              <br />
              Please inform us, if you are interested in this feature or have
              any suggestions, questions or requests. <br />
              <v-sheet class="text">
                <br />
                <a
                  class="darkGrey--text"
                  href="mailto:cloud@quantumsimulations.de"
                >
                  cloud@quantumsimulations.de
                </a>
              </v-sheet>
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions class="mt-12">
        <v-spacer></v-spacer>
        <div class="mr-3">
          <v-btn data-cy="clear" class="clear" text rounded color="darkGrey" @click="clear"
            >Clear</v-btn
          >
          <v-btn
            :loading="loading"
            data-cy="validate_lattice"
            rounded
            color="success"
            @click="triggerValidation"
            :disabled="!valid"
            >Validate Lattice</v-btn
          >
        </div>
      </v-card-actions>
    </v-form>
    <!-- warning dialog for decreased visibility in lattice plot for large translation offsets -->
    <v-dialog v-model="isBigTranslation" max-width="750">
      <v-card>
        <v-toolbar elevation="0" class="text mt-15">
          <v-toolbar-title class="text"
            >Large Translation detected!</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-alert outlined type="warning" text prominent border="left">
          The Bond with the largest Translation determines the size of the
          lattice graph.
          <br />
          The largest value in all translation-vectors corresponds to the number
          of rounds of
          <br />
          neighboring cell that are plotted. <br />
          A large translation might lead to a very large plot that is difficult
          to interact with.<br />
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="closeAlertDialog"> ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ValidateAndSave
      ref="validateLattice"
      @hideSpinner="hideSpinner($event)"
      @clearFormInParent="clear($event)"
    />
    <!-- dialog for converting between spinful and spinless -->
    <v-dialog persistent v-model="conversionAlert" width="700">
      <v-card>
        <v-alert
          class="alert"
          outlined
          type="warning"
          text
          prominent
          border="left"
          icon="mdi-chat-alert-outline"
        >
          <p v-if="latticeType.includes('spinless')">
            Converting your lattice to spinless will lead to the deletion of all
            parameters specific for spinful fermion lattices.
          </p>
          <p v-if="latticeType.includes('spinful')">
            Converting your lattice to spinful will lead to the deletion of all
            parameters specific for spinless fermion lattices.
          </p>
          <v-row>
            <v-col cols="7"></v-col>
            <v-col cols="5">
              <v-btn text color="darkGrey" @click="cancelConversion"
                >Cancel</v-btn
              >
              <v-btn
                data-cy="convert"
                rounded
                dark
                depressed
                color="hqsOrange"
                @click="convert"
                >Convert</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const ValidateAndSave = () =>
  import(/* webpackChunkName: "validateAndSave" */ './ValidateAndSave.vue')
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ './InfoDialog.vue')
const SitesTable = () =>
  import(/* webpackChunkName: "sitesTable" */ './SitesTable.vue')
const BondsTable = () =>
  import(/* webpackChunkName: "tables" */ './BondsTable.vue')
const LoadDropdown = () =>
  import(/* webpackChunkName: "navs" */ '../general/LoadDropdown.vue')
const BondsTablesSpinfulFermions = () =>
  import(
    /* webpackChunkName: "bondsTableSpinfulFermions" */ './fermionLattice/BondsTablesSpinfulFermions.vue'
  )
const SitesTablesSpinfulFermions = () =>
  import(
    /* webpackChunkName: "navs" */ './fermionLattice/SitesTablesSpinfulFermions.vue'
  )
import utils from '../../appUtils/utils.js'
import { mapFields } from 'vuex-map-fields'
import { mapMutations, mapState, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  components: {
    ValidateAndSave,
    InfoDialog,
    SitesTable,
    BondsTable,
    LoadDropdown,
    BondsTablesSpinfulFermions,
    SitesTablesSpinfulFermions,
  },

  data: () => ({
    conversionAlert: false,
    boundaryCond: ['hard-wall', 'periodic', 'anti-periodic'],
    isBigTranslation: false,
    valid: true,
    // ToDo: beautify rules handling (see eureqa)
    rule_required: [(v) => !!v || 'Required'],
    rule_intAboveZero: [
      (v) => !!v || 'Required',
      (v) =>
        (Number.isInteger(Number(v)) && v > 0) ||
        'Value must be integer value > 0.',
    ],
    rules_settings: [(v) => !!v || v === 0 || 'Required'],
    rules_integers: [
      (v) =>
        Number.isInteger(Number(v)) ||
        v == null ||
        'Value must be integer value',
    ],
    rule_mod_sz: [
      (v) => v == null || v == 0 || v == 2 || 'Value must be 0 or 2.',
    ],
    rule_mod_n: [
      (v) => v == null || v % 2 == 0 || 'Value must be an even integer.',
    ],
    showSpinner: false,
    loading: false,
  }),

  props: {
    isDirty: Boolean,
  },

  methods: {
    ...mapMutations('lattice', [
      'set',
      'resetLattice',
      'updatePlot',
      'setHasLattice',
      'convertType',
    ]),

    ...mapMutations('backend', {
      setBackendStore: 'set',
    }),

    ...mapActions('backend', ['loadById']),

    // todo: do the conversion of types here
    showConversionAlert() {
      if (
        this.latticeType != '' &&
        (this.bonds.length != 0 || this.sites.length != 0)
      ) {
        this.conversionAlert = true
        this.updateGraph()
      }
    },

    convert() {
      this.convertType(this.type)
      this.conversionAlert = false
      this.updatePlot()
      window.location.reload()
    },

    cancelConversion() {
      if (this.type.includes('spinful')) {
        this.latticeType = 'spinless fermions'
      } else if (this.type.includes('spinless')) {
        this.latticeType = 'spinful fermions'
      }
      this.conversionAlert = false
    },

    updateGraph() {
      if (!this.hasLattice && this.sites != undefined) {
        this.setHasLattice()
      }
      this.updatePlot()
    },

    updateGraphWithDebounce: _.debounce(function () {
      if (!this.hasLattice && this.sites != undefined) {
        this.setHasLattice()
      }
      this.updatePlot()
    }, 500),

    triggerValidation() {
      this.$refs.form.validate()
      this.loading = true
      setTimeout(() => (this.loading = false), 7000)
      this.showSpinner = true
      this.$refs.validateLattice.prepareValidation()
    },

    clear() {
      this.resetLattice()
      this.$refs.form.resetValidation()
      this.$emit('dirty', false)
      this.setBackendStore(['data', {}])
    },

    closeEditOrSaveDialog() {
      this.showEditOrSaveDialog = false
    },

    closeAlertDialog() {
      this.isBigTranslation = false
    },

    hideSpinner() {
      this.showSpinner = false
    },

    flagDirty() {
      this.$emit('dirty', true)
    },
  },

  computed: {
    ...mapState('lattice', {
      schema: (state) => state.schema,
      sites: (state) => state.sites,
      _uuid: (state) => state._uuid,
      bonds: (state) => state.bonds,
      isLargeOfset: (state) => state.isLargeOfset,
      hasLattice: (state) => state.hasLattice,
      vectors: (state) => state.latticeVectors,
    }),

    ...mapState('backend', {
      schemas: (state) => state.schemas,
    }),

    ...mapFields('lattice', [
      'latticeType',
      'latticeVectorA',
      'latticeVectorB',
      'label',
      'description',
      'clusterSize',
      'boundaryConditions',
      'system.spin_representation',
      'system.Sz',
      'system.mod_Sz',
      'system.mod_N',
      'system.N',
    ]),

    title() {
      if (this.$route.name.includes('lattice-builder-input')) {
        return 'Spin'
      } else {
        return 'Fermion'
      }
    },

    latticeTypes() {
      return ['spinful fermions', 'spinless fermions']
    },
  },

  watch: {
    isLargeOfset() {
      if (this.isLargeOfset) {
        this.isBigTranslation = true
      }
    },
  },

  async created() {
    let schema = this.schemas.find(
      (e) => e.label === utils.getLabelFromPathName(this.$route.name)
    )

    if (!schema || !schema._uuid) {
      return
    }

    let fullSchema = await this.loadById(['schema', schema._uuid])
    this.set(['schema', fullSchema])
  },
}
</script>

<style scoped>
.highlight {
  background-color: #9bbee2 !important;
}

.alert {
  margin-bottom: 0 !important;
}

.header {
  font-size: 1.2em !important;
}

.panels {
  margin: auto;
  width: 95%;
}
</style>
